import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/var/jenkins_home/workspace/affilate_mono_repo_vilkencykel/gatsby/src/components/mdxLayout.js";
import { graphql, Link } from "gatsby";
import ProductsDisplay from "../../components/productDisplay.js";
import CategoriesLinks from "../../components/categoriesLinks.js";
export const query = graphql`
  query {
    allMongodbStoreProducts(sort: {fields: popularity_score, order: DESC}, filter: {
        derived_properties: {frame_type: {
          in: ["hybridcykel, dam", "hybridcykel, herr", "hybridcykel"]
        }}
      }) {
      edges {
        node {
          	brand
			full_name
			id
			local_image_name
			model
			original_prices
			popularity_score
			prices
			product_id
			similair_products
			show_on_pages
			sub_product_type
			store
			url
			engine
			last_updated_date
			derived_properties {
				motor_type
				average_range_km
				number_of_gears
				battery_capacity
				frame_type
			}
			fields {
				brand_url
				full_name
				full_name_url
				model_url
			}
        }
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "hybridcyklar-fördelarna-egenskaperna-och-hur-du-väljer-den-rätta"
    }}>{`Hybridcyklar: Fördelarna, egenskaperna och hur du väljer den rätta`}</h1>
    <p>{`En hybridcykel kan ibland misstolkas som en el-hybridcykel, dvs en cykel som även har en elmotor, men detta är fel. Med hybdircykel menar man en hybrid mellan en mountainbike och en stadscykel. Den kombinerar de bästa egenskaperna från en stadscykel med de bästa egenskaperna från en mountainbike. Detta gör att du får en cykel som funkar utmärkt på välasfalterade vägar på vägen till jobbet, samtidigt som de funkar minst lika bra på den där skogsvägen som gör att du sparar 10 minuter. En hybridcykel är helt enkelt ett utmärkt val för dig som varierar din cykling men av en eller flera anledningar inte vill ha en specifik cykel till varje aktivitet.`}</p>
    <h2 {...{
      "id": "det-bästa-från-en-mountainbike-kombinerat-med-en-stadscykel"
    }}>{`Det bästa från en mountainbike kombinerat med en stadscykel`}</h2>
    <p>{`Från en mountainbike får man egenskaper så som lite bredare däck, fjädring, sportigare ram och en cykelposition som är något framlutad. De bredare däcken gör att du får bättre grepp vid dåligt underlag, så som snö, lera eller en skogsstig. Fjädringen gör att cyklingen blir mer behaglig vid ojämnt underlag du dessa absorberar en stor del av de vibrationer som kommer från underlaget. Och slutligen så ger den lite mer framåtlutande positionen på en hybridcykel att du enklare, och effektivare, kan få ut mer kraft från dina ben till tramporna.`}</p>
    <p>{`Från en stadscykel har man tagit egenskaper så som en något mer bekväm cykel med bättre packmöjligheter på t.ex. ram styre och pakethållare. Samtidigt har en hybridcykel något smalare däck än en mountainbike för att göra den lite mer lättcyklad. För tjocka däck gör att det blir mer friktion mot underlaget vilket gör det lite jobbigare att cykla, därför har hybridcyklar sällan lika tjocka däck som en mountainbike. Packmöjligheterna på t.ex. pakethållaren eller på styret gör hybridcyklar utmärkta för dig som pendlar till jobbet eller vill få med dig en kasse mat fårn mataffären.`}</p>
    <h2 {...{
      "id": "är-en-hybdircykel-något-för-dig"
    }}>{`Är en hybdircykel något för dig?`}</h2>
    <p>{`En hybridcykel är perfekt om du behöver en bra allaround-cykel som ska funka både till jobbpendling och en tur till mataffären, men också på en cykelsemester med barnkärra där bak eller en tur i skogen. En hybridcykel är inte 100% perfekt för någon av dessa användingsområden, men den är så nära man kan komma en perfekt kombination. Det är många av oss som varken har pengarna, tiden, platsen eller intresset att äga flera cyklar, och då kan en hybridcykel vara perfekt.`}</p>
    <CategoriesLinks mdxType="CategoriesLinks"></CategoriesLinks>
    <ProductsDisplay data={props.data.allMongodbStoreProducts} location={props.location} mdxType="ProductsDisplay"></ProductsDisplay>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      